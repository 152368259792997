<template>
    <client-page>

        <sub-visual :tit="this.$route.query.category" bg="/images/sub/visual/sv--product.jpg"></sub-visual>

        <section class="section">
            <v-container>
                <router-link v-if="linkCode === 'led'" to='/solution/led' class="text-btn d-flex align-center"><i class="icon icon-left-arrow mr-8"></i>Back to product family</router-link>
                <router-link v-else to='/solution/non-lighting' class="text-btn d-flex align-center"><i class="icon icon-left-arrow mr-8"></i>Back to product family</router-link>
                <div class="mt-12 mt-lg-20">
                    <v-row>
                        <v-col cols="6" md="3" v-for="product, idx in products" :key="idx">
                            <div @click="show(product._id)" style="cursor: pointer" class="h-100">
                                <card-primary>
                                    <div class="thumb">
                                        <div class="thumb__inner" :style="'background-image:url('+ product?.thumb +');'"></div>
                                    </div>
                                    <div class="w-100 pa-20">
                                        <p class="page-text line-height-13 word-keep-all ellip">
                                            {{product.subject}}
                                        </p>
                                    </div>
                                </card-primary>
                            </div>
                        </v-col>
                    </v-row>
                </div>

                <div class="v-pagination-wrap">
                    <v-pagination v-model="page" :length="pageCount" @input="search"/>
                </div>

            </v-container>
        </section>

        <!-- <v-container>
            <div class="tit-wrap text-center">
                <h2 class="tit tit--lg">{{this.$route.query.product}}</h2>
            </div>
            <div v-for="product, idx in products" :key="idx">
                <v-card @click="show(product._id)">
                    <v-img :src="product.thumb" width="300px"/>
                    <v-divider />
                    <v-content>{{product.subject}}</v-content>
                </v-card>
            </div>
            <div class="v-pagination-wrap">
                <v-pagination v-model="page" :length="pageCount" @input="search"/>
            </div>
        </v-container> -->
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import CardPrimary from "@/components/dumb/card-primary.vue";

import api from "@/api";

export default {
    components: {
        ClientPage,
        SubVisual,
        CardPrimary,
    },
    data() {
        return {
            programs: [],
            products: this.$route.query.category,

            page: 1,
            pageCount: 0,
			limit: 8,

            linkCode: null,
            category: this.$route.query.category
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
			this.search();
		},
        async search() {
            this.code = this.$route.query.category;
            let { summary, boards } = await api.v1.boards.gets({ 
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                    sort: "priority" 
                },
                params: { category: this.code, language: "ko" } });
            this.products = boards;

            let { programs } = await api.v1.auction.programs.gets({ params: { subject: this.category } });
            this.linkCode = programs[0].code;

            this.pageCount = Math.ceil(summary.totalCount / this.limit);

        },
        show(board){
            this.$router.push(`/series/product/${board}`);
        }
    },
    watch: {
        "$route.query.category"() {
            this.init();
        }
    }
};
</script>
