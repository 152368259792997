var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "tit": this.$route.query.category,
      "bg": "/images/sub/visual/sv--product.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_vm.linkCode === 'led' ? _c('router-link', {
    staticClass: "text-btn d-flex align-center",
    attrs: {
      "to": "/solution/led"
    }
  }, [_c('i', {
    staticClass: "icon icon-left-arrow mr-8"
  }), _vm._v("Back to product family")]) : _c('router-link', {
    staticClass: "text-btn d-flex align-center",
    attrs: {
      "to": "/solution/non-lighting"
    }
  }, [_c('i', {
    staticClass: "icon icon-left-arrow mr-8"
  }), _vm._v("Back to product family")]), _c('div', {
    staticClass: "mt-12 mt-lg-20"
  }, [_c('v-row', _vm._l(_vm.products, function (product, idx) {
    return _c('v-col', {
      key: idx,
      attrs: {
        "cols": "6",
        "md": "3"
      }
    }, [_c('div', {
      staticClass: "h-100",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.show(product._id);
        }
      }
    }, [_c('card-primary', [_c('div', {
      staticClass: "thumb"
    }, [_c('div', {
      staticClass: "thumb__inner",
      style: 'background-image:url(' + (product === null || product === void 0 ? void 0 : product.thumb) + ');'
    })]), _c('div', {
      staticClass: "w-100 pa-20"
    }, [_c('p', {
      staticClass: "page-text line-height-13 word-keep-all ellip"
    }, [_vm._v(" " + _vm._s(product.subject) + " ")])])])], 1)]);
  }), 1)], 1), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }